import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/alex/Documents/gatsby/g-talistreit-com/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <div className="columns is-multiline">
      <div className="column">
        <div className="content">
          <figure className="image is-hidden-desktop">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "320px"
                }
              }}>{`
      `}<a parentName="span" {...{
                  "className": "gatsby-resp-image-link",
                  "href": "/static/eee819cc348af10c200fa2d59aeb4afc/cb69c/thought.jpg",
                  "style": {
                    "display": "block"
                  },
                  "target": "_blank",
                  "rel": "noopener"
                }}>{`
    `}<span parentName="a" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "75%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAMCBAX/xAAVAQEBAAAAAAAAAAAAAAAAAAABAv/aAAwDAQACEAMQAAABZOswMoYXH//EABoQAAICAwAAAAAAAAAAAAAAAAECAxEAEiH/2gAIAQEAAQUCXGUlTwh6EM+xloP/AP/EABURAQEAAAAAAAAAAAAAAAAAABAR/9oACAEDAQE/Aaf/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAaEAACAgMAAAAAAAAAAAAAAAABEAACESGC/9oACAEBAAY/Apo4ZoeV/8QAGxABAAICAwAAAAAAAAAAAAAAAQARITFBcaH/2gAIAQEAAT8hl2iDHcNpepYLxAIVfqBAOtz/2gAMAwEAAgADAAAAENgf/8QAFhEBAQEAAAAAAAAAAAAAAAAAAQAx/9oACAEDAQE/EAZN/8QAFxEBAQEBAAAAAAAAAAAAAAAAAQARMf/aAAgBAgEBPxDHsN//xAAaEAEBAQADAQAAAAAAAAAAAAABEQAhMUGR/9oACAEBAAE/ECTPVTsVTmnmpTpxVUFcapc6KeOfuW5YIOL7N//Z')",
                      "backgroundSize": "cover",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="a" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "Picture of Alex",
                    "title": "Picture of Alex",
                    "src": "/static/eee819cc348af10c200fa2d59aeb4afc/cb69c/thought.jpg",
                    "srcSet": ["/static/eee819cc348af10c200fa2d59aeb4afc/f93b5/thought.jpg 300w", "/static/eee819cc348af10c200fa2d59aeb4afc/cb69c/thought.jpg 320w"],
                    "sizes": "(max-width: 320px) 100vw, 320px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy"
                  }}></img>{`
  `}</a>{`
    `}</span></p>
          </figure>
          <h1>{`What's in a name?`}</h1>
          <figure className="image is-hidden-touch is-pulled-right" style={{
            "width": "320px"
          }}>
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "320px"
                }
              }}>{`
      `}<a parentName="span" {...{
                  "className": "gatsby-resp-image-link",
                  "href": "/static/eee819cc348af10c200fa2d59aeb4afc/cb69c/thought.jpg",
                  "style": {
                    "display": "block"
                  },
                  "target": "_blank",
                  "rel": "noopener"
                }}>{`
    `}<span parentName="a" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "75%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAMCBAX/xAAVAQEBAAAAAAAAAAAAAAAAAAABAv/aAAwDAQACEAMQAAABZOswMoYXH//EABoQAAICAwAAAAAAAAAAAAAAAAECAxEAEiH/2gAIAQEAAQUCXGUlTwh6EM+xloP/AP/EABURAQEAAAAAAAAAAAAAAAAAABAR/9oACAEDAQE/Aaf/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAaEAACAgMAAAAAAAAAAAAAAAABEAACESGC/9oACAEBAAY/Apo4ZoeV/8QAGxABAAICAwAAAAAAAAAAAAAAAQARITFBcaH/2gAIAQEAAT8hl2iDHcNpepYLxAIVfqBAOtz/2gAMAwEAAgADAAAAENgf/8QAFhEBAQEAAAAAAAAAAAAAAAAAAQAx/9oACAEDAQE/EAZN/8QAFxEBAQEBAAAAAAAAAAAAAAAAAQARMf/aAAgBAgEBPxDHsN//xAAaEAEBAQADAQAAAAAAAAAAAAABEQAhMUGR/9oACAEBAAE/ECTPVTsVTmnmpTpxVUFcapc6KeOfuW5YIOL7N//Z')",
                      "backgroundSize": "cover",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="a" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "Picture of Alex",
                    "title": "Picture of Alex",
                    "src": "/static/eee819cc348af10c200fa2d59aeb4afc/cb69c/thought.jpg",
                    "srcSet": ["/static/eee819cc348af10c200fa2d59aeb4afc/f93b5/thought.jpg 300w", "/static/eee819cc348af10c200fa2d59aeb4afc/cb69c/thought.jpg 320w"],
                    "sizes": "(max-width: 320px) 100vw, 320px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy"
                  }}></img>{`
  `}</a>{`
    `}</span></p>
          </figure>
          <p className="subtitle is-size-5">
    In Switzerland there <b>used</b> to be restrictions on what first name you can give your children.
          </p>
          <p>{`My parents wanted to call me Taliesin, which was not allowed. So, I was born Alexander Talesin.
Later, a name change was made to rename me to Taliesin Alexander. The Australian authorities know me
based on my birth certificate. So in Australia I am Alexander and in Switzerland I am Taliesin.`}</p>
          <p>{`Just to make it more interesting, if you pay close attention you'll notice that my name was misspelled
when I was born. This caused a lot of confusion for several banks and government agencies. My favorite
part about this is how diligently people will follow what their screen tells them. If a computer tells
you that I don’t exist, then who am I to argue?`}</p>
          <p>{`So far, I’ve been allowed to continue not-existing.`}</p>
        </div>
      </div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      